<template>
  <div>
    <!-- {{ form }} -->
    <b-card>
      <div>
        <b-card-text>
          <h5 class="mb-2">
            Hier können Sie Angebots- oder Vertragsrelevante Dokumente
            hochladen. Auch Fotos bzgl. Schäden oder Auszug können hochgeladen
            werden.
          </h5>
        </b-card-text>
      </div>
      <div>
        <b-form>
          <b-row>
            <b-col sm="12" md="12" lg="10" xl="8">
              <b-card>
                <b-card-body>
                  <b-row>
                    <b-col sm="12" md="8" lg="8" xl="6">
                      <b-form-group
                        id="input-group-3"
                        label="Wählen Sie Ihr Anliegen aus:"
                        label-for="input-3"
                        description=""
                      >
                        <b-button-group>
                          <b-button
                            v-for="option in docOptions"
                            variant="primary"
                            :key="option.value"
                            :pressed="form.documentSelect === option.value"
                            @click="form.documentSelect = option.value"
                          >
                            {{ option.text }}
                          </b-button>
                        </b-button-group>

                        <!-- <b-form-select
                          id="input-3"
                          v-model="form.documentSelect"
                          required
                          placeholder="Bitte auswählen..."
                          :options="docOptions"
                        ></b-form-select> -->
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col sm="12" md="8" lg="8" xl="6">
                      <!-- <div class="pt-1">
          <h5>Art des Anliegen</h5>
        </div> -->
                      <b-form-group
                        id="input-group-1"
                        label="Bitte E-Mail eingeben:"
                        label-for="input-1"
                        description=""
                      >
                        <b-form-input
                          id="input-1"
                          v-model="form.email"
                          type="email"
                          placeholder="ihre@mail.de"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <!-- <div class="pt-1">
          <h5>Art des Anliegen</h5>
        </div> -->

                  <b-row>
                    <b-col sm="12" md="8" lg="8" xl="6">
                      <b-form-group
                        id="input-group-1"
                        :label="
                          form.documentSelect === '1'
                            ? 'Bitte Angebotsnummer eingeben:'
                            : 'Bitte Rechnungsnummer eingeben:'
                        "
                        label-for="input-1"
                        description=""
                      >
                        <b-form-input
                          id="input-1"
                          v-model="form.documentID"
                          type="number"
                          placeholder="01234567890"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="form.documentSelect === '3'">
                    <b-col sm="12" md="8" lg="8" xl="6">
                      <b-form-group
                        id="input-group-1"
                        label="Bitte Containernummer eingeben:"
                        label-for="input-1"
                        description=""
                      >
                        <b-form-input
                          id="input-1"
                          v-model="form.containerID"
                          type="text"
                          placeholder="MS-O-1234"
                          required
                        ></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row v-if="form.documentSelect === '3'">
                    <b-col sm="12" md="8" lg="8" xl="6">
                      <b-form-group
                        id="input-group-1"
                        label="Wann ist der Container wieder frei?"
                        label-for="input-1"
                        description=""
                      >
                        <b-form-datepicker
                          id="datepicker"
                          v-model="form.quitDate"
                          placeholder="Wählen Sie ein Datum aus..."
                          class="mb-2"
                        ></b-form-datepicker>
                        <!-- <b-form-input
                          id="input-1"
                          v-model="form.documentID"
                          type="number"
                          placeholder="01234567890"
                          required
                        ></b-form-input> -->
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>

          <b-button
            class="mb-3"
            type="button"
            variant="primary"
            @click="checkDocumentExistence"
            >Prüfen</b-button
          >
          <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
        </b-form>
      </div>
      <div>
        <b-row>
          <b-col sm="12" md="12" lg="10" xl="8">
            <b-card>
              <b-card-body>
                <b-row>
                  <b-col>
                    <b-card-text>
                      <p v-if="!isValidRequest" style="color: red">
                        Gesperrt. Bitte prüfen Sie Ihre E-Mail und
                        Dokumentennummer.
                      </p>

                      <div v-else style="color: green">
                        Freigegeben. Sie können nun Dokumente hochladen.
                      </div>

                      <h4 class="font-weight-bolder">Hochladen</h4>
                      <p>
                        Sie können mehrere Dokumente gleichzeitig hochladen.
                      </p>
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="8" lg="8" xl="6">
                    <b-form-group
                      id="input-group-1"
                      label-for="input-1"
                      description=""
                    >
                      <b-form-file
                        :disabled="!isValidRequest"
                        v-model="files"
                        multiple
                        :state="Boolean(file)"
                        placeholder="Dokument(e) auswählen..."
                        drop-placeholder="Dokument(e) auswählen..."
                      ></b-form-file>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
            <!-- Nachricht -->
            <b-card>
              <b-card-body>
                <b-row>
                  <b-col>
                    <b-card-text>
                      <h4 class="font-weight-bolder">Nachricht</h4>
                      <p>
                        Beschreiben Sie doch kurz wie wir Ihnen helfen können.
                      </p>
                    </b-card-text>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="12" md="12" lg="10" xl="10">
                    <b-form-group
                      id="input-group-1"
                      label-for="input-1"
                      description=""
                    >
                      <b-form-textarea
                        id="input-1"
                        v-model="form.userMessage"
                        type="textarea"
                        placeholder="Ihre Nachricht..."
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
            <b-row>
              <b-col>
                <b-card-text>
                  <b-button
                    variant="primary"
                    @click="uploadToBackend"
                    :disabled="!files"
                  >
                    Absenden
                  </b-button>
                </b-card-text>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      id="modal-check-success"
      title="Prüfung"
      ok-title="OK"
      modal-class="modal-success"
      centered
    >
      <b-card-text class="my-2">
        Prüfung erfolgreich. Sie können nun Dokumente hochladen.
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-check-false"
      title="Prüfung"
      ok-variant="danger"
      ok-title="OK"
      modal-class="modal-danger"
      centered
    >
      <b-card-text class="my-2">
        Prüfung fehlgeschlagen. Bitte prüfen Sie Ihre E-Mail und
        Dokumentennummer.
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-send-success"
      title="Erfolgreich"
      ok-title="OK"
      @ok="resetForm"
      modal-class="modal-success"
      centered
    >
      <b-card-text class="my-2">
        Hochladen erfolgreich. Vielen Dank.
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import API from '@/service/api'
import store from '@/store'
import { ref } from '@vue/composition-api'
import axios from 'axios'
import {
  BBadge,
  BButton,
  BButtonGroup,
  BCard,
  BCol,
  BContainer,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BModal,
  BRow,
  VBModal,
} from 'bootstrap-vue'
export default {
  components: {
    BButton,
    BButtonGroup,
    BCard,
    BFormFile,
    BContainer,
    BRow,
    BCol,
    BFormInput,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    BLink,
    BFormSelect,
    BFormGroup,
    BFormTextarea,
    BModal,
    VBModal,
  },
  setup() {
    const file = ref(null)
    const uploadFile = () => {
      console.log(file.value)
    }
    return {
      file,
      uploadFile,
    }
  },
  data() {
    return {
      baseURL: API().defaults.baseURL,
      form: {
        email: null,
        documentSelect: null,
        documentType: null,
        documentID: null,
        userMessage: null,
        customer: null,
        offer: null,
        order: null,
        containerID: null,
        quitDate: null,
      },
      files: null,
      isValidRequest: false,
      docOptions: [
        // { value: null, text: 'Bitte auswählen...' },
        { value: '1', text: 'Angebot' },
        { value: '2', text: 'Rechnung' },
        { value: '3', text: 'Kündigung' },
      ],
      attachment: {
        note: null,
        customer: null,
        offer: null,
        order: null,
        attachment_type: '7',
        created_by: 'Shop',
        updated_by: 'Shop',
        shop_upload: '',
        files: [],
      },
    }
  },

  computed: {},

  methods: {
    checkDocumentExistence() {
      const payload = {
        email: this.form.email,
        id: this.form.documentID,
      }

      // Entscheiden, welche Vuex-Action basierend auf der Auswahl im form.docType aufgerufen wird
      let actionName
      if (this.form.documentSelect === '1') {
        // Angenommen '1' steht für Angebot
        actionName = 'checkEmailAndOffer'
        this.form.offer = this.form.documentID
        this.form.documentType = 'Angebot'
      } else if (this.form.documentSelect === '2') {
        // Angenommen '2' steht für Rechnung
        actionName = 'checkEmailAndOrder'
        this.form.order = this.form.documentID
        this.form.documentType = 'Rechnung'
      } else if (this.form.documentSelect === '3') {
        // Angenommen '3' steht für Kündigung
        actionName = 'checkEmailAndOrder'
        this.form.order = this.form.documentID
        this.form.documentType = 'Kündigung'
        this.form.userMessage += `\nContainer-ID: ${this.form.containerID} - Frei ab: ${this.form.quitDate}` // Nachricht anpassen
      }

      // Sicherstellen, dass eine Aktion ausgewählt wurde
      if (actionName) {
        this.$store
          .dispatch(actionName, payload)
          .then(response => {
            // Erfolgsfall: Verarbeiten der Antwort
            console.log('Response:', response.exists)

            if (response.exists === true) {
              this.isValidRequest = true
              this.$bvModal.show('modal-check-success')
            } else {
              this.isValidRequest = false
              this.$bvModal.show('modal-check-false')
            }
          })
          .catch(error => {
            // Fehlerfall: Behandeln des Fehlers
            console.error('Fehler bei der Überprüfung', error)
            this.isValidRequest = false
          })
      } else {
        // Fehler oder ungültige Auswahl behandeln
        console.error('Ungültige Dokumentenart ausgewählt')
      }
    },

    uploadToBackend() {
      console.log('uploadToBackend')

      this.$store
        .dispatch('postUserShopUploads', this.form)
        .then(response => {
          console.log('Response:', response)
          this.attachment.shop_upload = response.id
          this.uploadFilesAndAttachments()
        })
        .catch(error => {
          console.error('Fehler beim Hochladen', error)
        })
    },

    uploadFilesAndAttachments() {
      const formData = new FormData()

      for (let i = 0; i < this.files.length; i += 1) {
        const file = this.files[i]

        formData.append('files', file)
      }

      const config = {
        onUploadProgress: uploadEvent => {
          console.log(
            `Upload Progress: ${Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )} %`
          )
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      this.$store
        .dispatch('addFile', formData, config)
        .then(result => {
          console.log('Files:', result)
          this.attachment.files = result.map(a => a.id)
          this.attachment.customer = this.form.customer
          this.attachment.offer = this.form.offer
          this.attachment.order = this.form.order
          // this.attachment.files.push(file.id)

          this.$store
            .dispatch('addAttachment', this.attachment)
            .then(result => {
              console.log('Attachment:', result)
              this.$bvModal.show('modal-send-success')
            })
            .catch(error => {
              console.error('Fehler beim Hochladen', error)
            })
        })
        .catch(error => {
          console.error('Fehler beim Hochladen', error)
        })
    },
    resetForm() {
      this.$bvModal.hide('modal-send-success')
      this.$router.push('/e-commerce/shop')
    },
  },
}
</script>
